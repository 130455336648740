// eslint-disable-next-line max-classes-per-file
import 'regenerator-runtime/runtime';
import * as THREE from './build/three.module';
import { OBJLoader } from './examples/jsm/loaders/OBJLoader';
import { MeshSurfaceSampler } from './examples/jsm/math/MeshSurfaceSampler';
import { RenderPass } from './examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from './examples/jsm/postprocessing/UnrealBloomPass';
import { EffectComposer } from './examples/jsm/postprocessing/EffectComposer';
import { OrbitControls } from './examples/jsm/controls/OrbitControls';
import { TWEEN } from './examples/jsm/libs/tween.module.min';
import { CSS3DRenderer, CSS3DObject } from './examples/jsm/renderers/CSS3DRenderer';
import { gridTech } from './three_tech_table';

const bpXS = window.matchMedia('(max-width: 768px)');
const mobile = bpXS.matches === true;
// Mountain
const elContent = document.querySelector('#mountain-three');
const pixelRatio = 2;

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
    mobile ? 70 : 30,
    elContent.offsetWidth / elContent.offsetHeight,
    0.001,
    1000
);

camera.position.z = 7;
camera.lookAt(-0.2, -2.5, 0);

const renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true
});
renderer.setPixelRatio(pixelRatio);
renderer.setSize(elContent.offsetWidth, elContent.offsetHeight);
elContent.appendChild(renderer.domElement);

const renderScene = new RenderPass(scene, camera);

const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(elContent.offsetWidth, elContent.offsetHeight),
    1.5,
    0.4,
    0.85
);
bloomPass.threshold = 0;
bloomPass.strength = 0.6;

const composer = new EffectComposer(renderer);
composer.setPixelRatio(pixelRatio);
composer.addPass(renderScene);
composer.addPass(bloomPass);

const group = new THREE.Group();
scene.add(group);

const sparkles = [];
const sparklesGeometry = new THREE.BufferGeometry();
const sparklesMaterial = new THREE.ShaderMaterial({
    uniforms: {
        pointTexture: {
            value: new THREE.TextureLoader().load(
                './assets/js/three/static/dotTexture.png'
            )
        }
    },
    vertexShader: document.getElementById('vertexshader').textContent,
    fragmentShader: document.getElementById('fragmentshader').textContent,
    blending: THREE.AdditiveBlending,
    alphaTest: 1.0,
    transparent: true
});
const points = new THREE.Points(sparklesGeometry, sparklesMaterial);
group.add(points);

let sampler = null;
const lines = [];
const linesMaterials = [
    new THREE.LineBasicMaterial({
        transparent: true,
        color: 0x125D98
    }),
    new THREE.LineBasicMaterial({
        transparent: true,
        color: 0xCFD6DE
    })
];
const galaxyColors = [
    new THREE.Color('#f9fbf2').multiplyScalar(0.8),
    new THREE.Color('#ffede1').multiplyScalar(0.8),
    new THREE.Color('#05c7f2').multiplyScalar(0.8),
    new THREE.Color('#0597f2').multiplyScalar(0.8),
    new THREE.Color('#0476d9').multiplyScalar(0.8)
];

let mountain = null;

function dots() {
    sampler = new MeshSurfaceSampler(mountain).build();

    for (let i = 0; i < 6; i++) {
        const linesMesh = new THREE.Line(new THREE.BufferGeometry(), linesMaterials[i % 2]);
        linesMesh.coordinates = [];
        linesMesh.previous = null;
        lines.push(linesMesh);
        group.add(linesMesh);
    }
    // eslint-disable-next-line no-use-before-define
    requestAnimationFrame(render);
}

const loader = new OBJLoader();
loader.load(
    './assets/js/three/static/Mountain_Model.obj',
    (obj) => {
        // eslint-disable-next-line prefer-destructuring
        mountain = obj.children[0];
        // mountain.geometry.scale(0.6, 0.6, 0.6);
        mountain.geometry.translate(0, -2, 0);
        mountain.geometry.rotateX(0.1);
        dots();
    }
    // (xhr) => console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`),
    // (err) => console.log('An error happened', err)
);

const p1 = new THREE.Vector3();

function nextDot(line) {
    let ok = false;
    while (!ok) {
        sampler.sample(p1);
        if (line.previous && p1.distanceTo(line.previous) < 0.3) {
            line.coordinates.push(p1.x, p1.y, p1.z);
            line.previous = p1.clone();

            for (let i = 0; i < 2; i++) {
                // eslint-disable-next-line no-use-before-define
                const spark = new Sparkle();
                spark.setup(p1, line.material.color);
                sparkles.push(spark);
            }
            ok = true;
        } else if (!line.previous) {
            line.previous = p1.clone();
        }
    }
}

function updateSparklesGeometry() {
    const tempSparklesArraySizes = [];
    const tempSparklesArrayColors = [];
    sparkles.forEach((s) => {
        tempSparklesArraySizes.push(s.size);
        tempSparklesArrayColors.push(s.color.r, s.color.g, s.color.b);
    });
    sparklesGeometry.setAttribute('color', new THREE.Float32BufferAttribute(tempSparklesArrayColors, 3));
    sparklesGeometry.setAttribute('size', new THREE.Float32BufferAttribute(tempSparklesArraySizes, 1));
}

class Sparkle extends THREE.Vector3 {

    setup(origin, color) {
        this.x = origin.x;
        this.y = origin.y;
        this.z = origin.z;
        this.v = new THREE.Vector3();
        /* X Speed */
        this.v.x = THREE.MathUtils.randFloat(0.001, 0.006);
        this.v.x *= Math.random() > 0.5 ? 1 : -1;
        /* Y Speed */
        this.v.y = THREE.MathUtils.randFloat(0.001, 0.006);
        this.v.y *= Math.random() > 0.5 ? 1 : -1;
        /* Z Speed */
        this.v.z = THREE.MathUtils.randFloat(0.001, 0.006);
        this.v.z *= Math.random() > 0.5 ? 1 : -1;

        this.size = Math.random() * 4 + 0.5 * pixelRatio;
        this.slowDown = 0.4 + Math.random() * 0.58;
        this.color = color;
    }

    update() {
        if (this.v.x > 0.001 || this.v.y > 0.001 || this.v.z > 0.001) {
            this.add(this.v);
            this.v.multiplyScalar(this.slowDown);
        }
    }

}

class Star {

    setup(color) {
        this.r = Math.random() * 12 + 3;
        this.phi = Math.random() * Math.PI * 2;
        this.theta = Math.random() * Math.PI;
        this.v = new THREE.Vector2().random()
            .subScalar(0.5)
            .multiplyScalar(0.0007);

        this.x = this.r * Math.sin(this.phi) * Math.sin(this.theta);
        this.y = this.r * Math.cos(this.phi);
        this.z = this.r * Math.sin(this.phi) * Math.cos(this.theta);

        this.size = Math.random() * 4 + 0.5 * pixelRatio;
        this.color = color;
    }

    update() {
        this.phi += this.v.x;
        this.theta += this.v.y;
        this.x = this.r * Math.sin(this.phi) * Math.sin(this.theta);
        this.y = this.r * Math.cos(this.phi);
        this.z = this.r * Math.sin(this.phi) * Math.cos(this.theta);
    }

}

/* Create stars */
const stars = [];
const galaxyGeometryVertices = [];
const galaxyGeometryColors = [];
const galaxyGeometrySizes = [];

for (let i = 0; i < 1500; i++) {
    const star = new Star();
    star.setup(galaxyColors[Math.floor(Math.random() * galaxyColors.length)]);
    galaxyGeometryVertices.push(star.x, star.y, star.z);
    galaxyGeometryColors.push(star.color.r, star.color.g, star.color.b);
    galaxyGeometrySizes.push(star.size);
    stars.push(star);
}
const starsGeometry = new THREE.BufferGeometry();
starsGeometry.setAttribute(
    'size',
    new THREE.Float32BufferAttribute(galaxyGeometrySizes, 1)
);
starsGeometry.setAttribute(
    'color',
    new THREE.Float32BufferAttribute(galaxyGeometryColors, 3)
);
const galaxyPoints = new THREE.Points(starsGeometry, sparklesMaterial);
scene.add(galaxyPoints);

let _prev = 0;

function render(a) {
    requestAnimationFrame(render);

    galaxyPoints.rotation.y += 0.0005;

    group.rotation.x = Math.sin(a * 0.0001) * 0.01;
    group.rotation.y += 0.0005;

    if (a - _prev > 30) {
        lines.forEach((l) => {
            if (mobile ? (sparkles.length < 10000) : (sparkles.length < 15000)) {
                nextDot(l);
                nextDot(l);
            }
            const tempVertices = new Float32Array(l.coordinates);
            l.geometry.setAttribute('position', new THREE.BufferAttribute(tempVertices, 3));
            l.geometry.computeBoundingSphere();
        });
        updateSparklesGeometry();
        _prev = a;
    }

    const tempSparklesArray = [];
    sparkles.forEach((s) => {
        s.update();
        tempSparklesArray.push(s.x, s.y, s.z);
    });

    sparklesGeometry.setAttribute('position', new THREE.Float32BufferAttribute(tempSparklesArray, 3));

    const tempStarsArray = [];
    stars.forEach((s) => {
        s.update();
        tempStarsArray.push(s.x, s.y, s.z);
    });

    starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(tempStarsArray, 3));

    composer.render();
}

function onWindowResize() {
    camera.aspect = elContent.offsetWidth / elContent.offsetHeight;
    camera.updateProjectionMatrix();
    composer.setSize(elContent.offsetWidth, elContent.offsetHeight);
    renderer.setSize(elContent.offsetWidth, elContent.offsetHeight);
    bloomPass.setSize(elContent.offsetWidth, elContent.offsetHeight);
}

window.addEventListener('resize', onWindowResize);

// Table

const container = document.getElementById('tech_canvas'),
    objects = [],
    targets = {
        table: [],
        sphere: [],
        helix: [],
        grid: []
    };
let camera_tech,
    scene_tech,
    renderer_tech,
    controls_tech;

function transform(targets, duration) {
    TWEEN.removeAll();
    for (let i = 0; i < objects.length; i++) {

        const object = objects[i];
        const target = targets[i];

        new TWEEN.Tween(object.position)
            .to({
                x: target.position.x,
                y: target.position.y,
                z: target.position.z
            }, Math.random() * duration + duration)
            .easing(TWEEN.Easing.Exponential.InOut)
            .start();

        new TWEEN.Tween(object.rotation)
            .to({
                x: target.rotation.x,
                y: target.rotation.y,
                z: target.rotation.z
            }, Math.random() * duration + duration)
            .easing(TWEEN.Easing.Exponential.InOut)
            .start();

    }
    new TWEEN.Tween(this)
        .to({}, duration * 2)
        .onUpdate(() => {
            renderer_tech.render(scene_tech, camera_tech);
        })
        .start();
}

function onWindowResizeTech() {
    camera_tech.aspect = container.clientWidth / container.clientHeight;
    camera_tech.updateProjectionMatrix();
    renderer_tech.setSize(container.clientWidth, container.clientHeight);
}

function init() {
    camera_tech = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.01, 10000);
    camera_tech.position.set(0, 0, 3000);
    scene_tech = new THREE.Scene();
    // Sphere
    renderer_tech = new CSS3DRenderer();
    renderer_tech.setSize(container.clientWidth, container.clientHeight);
    renderer_tech.domElement.style.position = 'absolute';
    renderer_tech.domElement.style.top = 0;
    container.appendChild(renderer_tech.domElement);

    controls_tech = new OrbitControls(camera_tech, renderer_tech.domElement);
    controls_tech.autoRotate = true;
    controls_tech.autoRotateSpeed = 2;
    controls_tech.maxDistance = 6000;
    controls_tech.minDistance = 2000;
    controls_tech.enablePan = false;

    // Tech Sphere
    // table
    function hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return `rgba(${r}, ${g}, ${b}, ${alpha})`;
        }
        return `rgb(${r}, ${g}, ${b})`;

    }

    const color = hexToRGB('#2f71db', `${Math.random() * 0.5 + 0.25}`);

    for (let i = 0; i < gridTech.length; i += 5) {
        const element = document.createElement('div');
        element.className = 'element';
        element.style.backgroundColor = color;

        // const number = document.createElement('div');
        // number.className = 'number';
        // number.textContent = (i / 5) + 1;
        // element.appendChild(number);

        const symbol = document.createElement('div');
        symbol.className = 'symbol';
        symbol.textContent = gridTech[i];
        element.appendChild(symbol);

        const details = document.createElement('div');
        details.className = 'details';
        // details.innerHTML = table[i + 1] + '<br>' + table[i + 2];
        details.innerHTML = gridTech[i + 1];
        element.appendChild(details);

        const objectCSS = new CSS3DObject(element);
        objectCSS.position.x = Math.random() * 4000 - 2000;
        objectCSS.position.y = Math.random() * 4000 - 2000;
        objectCSS.position.z = Math.random() * 4000 - 2000;

        scene_tech.add(objectCSS);
        objects.push(objectCSS);

        const object = new THREE.Object3D();
        object.position.x = (gridTech[i + 3] * 260) - 1200;
        object.position.y = -(gridTech[i + 4] * 220) + 940;

        targets.table.push(object);
    }

    // sphere

    const vector = new THREE.Vector3();
    for (let i = 0, l = objects.length; i < l; i++) {
        const phi = Math.acos(-1 + (2 * i) / l);
        const theta = Math.sqrt(l * Math.PI) * phi;
        const object = new THREE.Object3D();
        object.position.setFromSphericalCoords(mobile ? '400' : '800', phi, theta);

        vector.copy(object.position)
            .multiplyScalar(2);
        object.lookAt(vector);
        targets.sphere.push(object);
    }

    // helix

    for (let i = 0, l = objects.length; i < l; i++) {
        const theta = i * 0.175 + Math.PI;
        const y = -(i * 8) + 250;
        const object = new THREE.Object3D();
        object.position.setFromCylindricalCoords(1400, theta, y);

        vector.x = object.position.x * 2;
        vector.y = object.position.y;
        vector.z = object.position.z * 2;
        object.lookAt(vector);
        targets.helix.push(object);
    }

    // grid

    for (let i = 0; i < objects.length; i++) {
        const object = new THREE.Object3D();
        object.position.x = ((i % 5) * 400) - 800;
        object.position.y = (-(Math.floor(i / 5) % 5) * 400) + 800;
        object.position.z = (Math.floor(i / 25)) * 1000 - 2000;
        targets.grid.push(object);
    }

    //
    let timer1,
        timer2,
        timer3,
        timer4;

    // function animateSphere() {
    //     timer1 = setInterval(() => {
    //         transform(targets.grid, 2000);
    //     }, 3000);
    //     timer2 = setInterval(() => {
    //         transform(targets.helix, 2000);
    //     }, 6000);
    //     timer3 = setInterval(() => {
    //         transform(targets.sphere, 2000);
    //     }, 9000);
    //     timer4 = setInterval(() => {
    //         transform(targets.table, 2000);
    //     }, 12000);
    // }

    function stopAnimateSphere() {
        clearInterval(timer1);
        clearInterval(timer2);
        clearInterval(timer3);
        clearInterval(timer4);
        controls_tech.autoRotate = false;
    }

    // controls_universe.addEventListener('change', render_tech);
    // const buttonTable = document.getElementById('table'),
    //     buttonSphere = document.getElementById('sphere'),
    //     buttonHelix = document.getElementById('helix'),
    //     buttonGrid = document.getElementById('grid');
    //
    // buttonTable.addEventListener('click', (e) => {
    //     transform(targets.table, 2000);
    //     buttonSphere.classList.remove('active');
    //     buttonHelix.classList.remove('active');
    //     buttonGrid.classList.remove('active');
    //     e.currentTarget.classList.add('active');
    //     stopAnimateSphere();
    // });
    //
    // buttonSphere.addEventListener('click', (e) => {
    //     transform(targets.sphere, 2000);
    //     buttonTable.classList.remove('active');
    //     buttonHelix.classList.remove('active');
    //     buttonGrid.classList.remove('active');
    //     e.currentTarget.classList.add('active');
    //     stopAnimateSphere();
    // });
    //
    // buttonHelix.addEventListener('click', (e) => {
    //     transform(targets.helix, 2000);
    //     buttonTable.classList.remove('active');
    //     buttonSphere.classList.remove('active');
    //     buttonGrid.classList.remove('active');
    //     e.currentTarget.classList.add('active');
    //     stopAnimateSphere();
    // });
    //
    // buttonGrid.addEventListener('click', (e) => {
    //     transform(targets.grid, 2000);
    //     buttonTable.classList.remove('active');
    //     buttonSphere.classList.remove('active');
    //     buttonHelix.classList.remove('active');
    //     e.currentTarget.classList.add('active');
    //     stopAnimateSphere();
    // });

    transform(targets.sphere, 3000);
    window.addEventListener('resize', onWindowResizeTech, false);

}

function animate() {
    // Sphere Background Animation
    TWEEN.update();

    controls_tech.update();
    renderer_tech.render(scene_tech, camera_tech);
    requestAnimationFrame(animate);
}

init();
animate();
