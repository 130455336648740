export const gridTech = [
    'HTML', 'Hypertext Markup language', '30.973762', 1, 1,
    'CSS', 'Cascading Style Sheets', '32.065', 2, 1,
    'JS', 'JavaScript', '29.065', 4, 1,

    // 'LESS', 'Less CSS pre-processor', '10.811', 5, 1,
    // 'SASS', 'Syntactically Awesome Style Sheets', '10.811', 6, 1,
    // 'XHTML ', 'XML/HTML', '9.012182', 9, 1,

    // 'ECMAScript', 'JavaScript Library', '10.811', 1, 2,
    // 'Vue', 'Vue.js', '10.811', 2, 2,
    // 'Ang', 'Angular.js JavaScript MVW Framework', '30.973762', 3, 2,
    // 'React', 'React.js JavaScript Library', '32.065', 4, 2,
    'Node', 'JavaScript Runtime Environment', '37.065', 1, 2,
    // 'Ajax', 'Asynchronous JavaScript and XML', '10.811', 7, 2,
    // 'JSON', 'JavaScript Object Notation', '32.065', 8, 2,
    'cP', 'cPanel Web Hosting', '39.948', 3, 2,
    'CF', 'Cloudflare Web Performance & Security', '39.948', 4, 2,

    'BS', 'Bootstrap UI Framework', '46.941', 1, 3,
    'FND', 'Foundation UI Framework', '26.941', 2, 3,
    'ADB', 'Adobe System Solutions', '40.9994', 3, 3,
    'Skc', 'Sketch Software', '40.9994', 4, 3,
    'MySql', 'MySql', '35.9815386', 6, 3,
    // 'MDB', 'NoSQL database', '26.9815386', 7, 3,
    // 'FB', 'Firebase', '6.941', 8, 3,
    // 'O', 'Oracle Cloud Platform', '26.9815386', 9, 3,

    'WP', 'Wordpress CMS', '55.9984032', 1, 4,
    'Drp', 'Drupal CMS', '20.1797', 2, 4,
    'Jom', 'Joomla CMS', '28.98976...', 3, 4,
    // 'T3', 'Typo3 CMS', '6.078', 4, 4,
    // 'PK', 'PageKit CMS', '7.867', 5, 4,
    // 'MGT', 'E-commerce Platform', '7.867', 7, 4,
    // 'PS', 'PrestaShop', '27.867', 8, 4,
    // 'Sh', 'Shopify', '17.867', 9, 4,

    'CBO', 'Cross Browser Optimisation', '50.0067', 2, 5,
    'RWD', 'Responsive Web Design', '55.0107', 3, 5,
    'QA', 'Quality Assurance Testing', '45.0107', 4, 5,
    'VCS', 'Version Control System', '40.811', 5, 5,
    // 'Git', 'Git', '10.811', 6, 5,

    'PHP', 'PHP', '24.305', 1, 6,
    'Java', 'Oracle Java', '28.0855', 2, 6,
    // 'C#', 'C Sharp', '35.453', 3, 6,
    // '.net', 'Microsoft .net', '39.948', 4, 6,
    // 'Clj', 'Clojure ', '39.948', 5, 6,
    // 'VS', 'Microsoft Visual Studio', '44.955912', 6, 6,
    'Adr', 'Android OS Mobile Apps', '10.811', 8, 6,
    'iOS', 'iOS Mobile Apps', '10.811', 9, 6,
    'Flt', 'iOS Mobile Apps', '10.811', 10, 6,

    'VsId', 'Visual Identity', '12.0107', 2, 7,
    'CMS', 'Content Management Systems', '12.0107', 3, 7,
    'SMO', 'Social Media Marketing', '12.0107', 4, 7,
    'SEO', 'Search Engine Optimisation', '12.0107', 5, 7,
    'PP', 'Professional Photography', '12.0107', 8, 7,
    'VE', 'Video Editing', '12.0107', 9, 7
];
